.pictures {
  font-size: 17px;
  width: 100px;
  display: flex;
  align-items: center;
  margin-left: 800px;
  flex-direction: column;
}

h1 {
  margin-left: 750px;
}

h2 {
  padding-left: 3%;
}

.picturesdiv {
  width: 100%;
  display: flex;
  align-items: center;
}

.logout {
  margin-left: 90%;
  font-size: 30px;
}

body {
  font-size: 20px;
}

.d1 {
  font-size: 30px;
  width: 700px;
}

.d2 {
  font-size: 30px;
}
