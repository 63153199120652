.middle-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rounded-rectangle {
  width: 1684px;
  height: 413px;
  border-radius: 49px;
  border: black 2px solid;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container1 {
  margin-top: 60px;
}

.etapi-konkursu-text {
  display: flex;
  font-size: 25px;
  margin-left: -34px;
  top: 10px;
}

.blue-circle1 {
  width: 100px;
  height: 100px;
  background: #5fb5f3;
  border-radius: 50%;
}

.blue-circle1-text {
  text-align: center;
  line-height: 100px;
  font-size: 36px;
  color: black;
  margin-top: 3px;
}

.blue-circle2-text {
  text-align: center;
  line-height: 100px;
  font-size: 36px;
  color: black;
  z-index: 2;
  margin-top: 3px;
}

.blue-circle3 {
  width: 100px;
  height: 100px;
  background: #5fb5f3;
  border-radius: 50%;
  filter: blur(10px);
  text-align: center;
  line-height: 100px;
  font-size: 36px;
  color: black;
}

.blue-circle3-text {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: black;
  margin-top: 3px;
  position: relative;
}

.container1 {
  display: flex;
  width: "100%";
  justify-content: "space-evenly";
}

.blue-circle-span {
  position: absolute;
  z-index: 2;
}

.textall {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -150px;
  line-height: 1.5;
}

.text1 {
  display: flex;
  margin-left: 30px;
  justify-content: flex-start;
  align-items: center;
}

.text_down {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.container2 {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

.container3 {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

.vibir {
  width: 200px;
  text-align: center;
}

.arrow {
  position: relative;
  margin-top: 20px;
}

.optimize_phone {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

/* Laptop L */
@media (max-width: 1440px) {
  .middle-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rounded-rectangle {
    width: 100%;
    height: 413px;
    border-radius: 49px;
    border: black 2px solid;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .container3 {
    font-size: 15px;
  }

  .container2 {
    font-size: 15px;
  }

  .etapi-konkursu-text {
    font-size: 15px;
  }

  .blue-circle1-text {
    font-size: 1px;
  }

  .blue-circle2-text {
    font-size: 1px;
  }

  .blue-circle3 {
    font-size: 1px;
  }

  .blue-circle3-text {
    font-size: 20px;
  }

  .blue-circle1 {
    width: 70px;
    height: 70px;
  }

  .blue-circle3 {
    width: 70px;
    height: 70px;
  }

  .optimize_phone {
    font-size: 15px;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  .rounded-rectangle {
    width: 100%;
    margin-left: 0;
    padding: 1rem;
    height: 350px;
  }

  .arrow {
    margin-left: 1%;
  }

  .etapi_konkursu {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .rounded-rectangle {
    width: 80%;
    height: 413px;
    border-radius: 49px;
    border: black 2px solid;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .rounded-rectangle {
    height: 413px;
    border-radius: 49px;
    margin-top: 50px;
    width: fit-content;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .etapi-konkursu-text {
    display: flex;
    font-size: 15px;
    flex-direction: row;
  }

  .blue-circle1 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
  }

  .blue-circle1-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    margin-top: 3px;
  }

  .blue-circle2-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    z-index: 2;
    margin-top: 3px;
  }

  .blue-circle3 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
    filter: blur(10px);
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
  }

  .blue-circle3-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin-top: 3px;
    position: relative;
  }

  .arrow {
    display: none;
  }

  .container1 {
    display: flex;
    width: fit-content;
    flex-direction: column;
  }

  .blue-circle-span {
    position: absolute;
    z-index: 2;
  }

  .textall {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: -150px;
    line-height: 1.5;
  }

  .text1 {
    display: flex;
    margin-left: 30px;
    justify-content: flex-start;
    align-items: center;
  }

  .text_down {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .container2 {
    width: 350px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }

  .container3 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 20px;
    font-size: 20px;
  }

  .arrow {
    position: relative;
    margin-top: 20px;
  }

  .optimize_phone {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }
}

@media (max-width: 425px) {
  .etapi_konkursu {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .rounded-rectangle {
    height: 413px;
    border-radius: 49px;
    margin-top: 50px;
    width: fit-content;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .etapi-konkursu-text {
    display: flex;
    font-size: 23px;
    flex-direction: row;
  }

  .blue-circle1 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
  }

  .blue-circle1-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    margin-top: 3px;
  }

  .blue-circle2-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    z-index: 2;
    margin-top: 3px;
  }

  .container3 {
    margin-left: 75px;
  }

  .blue-circle3 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
    filter: blur(10px);
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
  }

  .blue-circle3-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin-top: 3px;
    position: relative;
  }

  .arrow {
    display: none;
  }

  .container1 {
    display: flex;
    width: fit-content;
    flex-direction: column;
  }

  .blue-circle-span {
    position: absolute;
    z-index: 2;
  }

  .textall {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: -150px;
    line-height: 1.5;
  }

  .text1 {
    display: flex;
    margin-left: 30px;
    justify-content: flex-start;
    align-items: center;
  }

  .text_down {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .container2 {
    width: 350px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }

  .container3 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 20px;
    font-size: 20px;
  }

  .arrow {
    position: relative;
    margin-top: 20px;
  }

  .optimize_phone {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }

  .container1 {
    gap: 60px;
  }
}

/* Mobile M */
@media (max-width: 375px) {
  .middle-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .rounded-rectangle {
    height: 413px;
    border-radius: 49px;
    border: black 2px solid;
    margin-top: 50px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 34%;
  }

  .etapi-konkursu-text {
    display: flex;
    font-size: 25px;
    flex-direction: row;
  }

  .blue-circle1 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
  }

  .blue-circle1-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    margin-top: 3px;
  }

  .blue-circle2-text {
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
    z-index: 2;
    margin-top: 3px;
  }

  .blue-circle3 {
    width: 100px;
    height: 100px;
    background: #5fb5f3;
    border-radius: 50%;
    filter: blur(10px);
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    color: black;
  }

  .blue-circle3-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin-top: 3px;
    position: relative;
  }

  .container1 {
    display: flex;
    width: fit-content;
    flex-direction: column;
  }

  .blue-circle-span {
    position: absolute;
    z-index: 2;
  }

  .textall {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: -150px;
    line-height: 1.5;
  }

  .text1 {
    display: flex;
    margin-left: 30px;
    justify-content: flex-start;
    align-items: center;
  }

  .text_down {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .container2 {
    width: 350px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }

  .container3 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 20px;
    font-size: 20px;
  }

  .arrow {
    position: relative;
    margin-top: 20px;
  }

  .optimize_phone {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }
}

/* Mobile S */
@media (max-width: 320px) {
  .rounded-rectangle {
    margin-left: 36.5%;
  }

  .arrow {
    margin-left: 250%;
  }
}
