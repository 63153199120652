.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.modal-content {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(60deg, #5fb5f3, #f7d984) border-box;
  border: solid transparent;
  border-radius: 50px;
  font-size: 22px;
  width: 800px;
  height: 300px;
}

.text-modal1 {
  font-size: 30px;
  text-align: center;
}

.text-modal2 {
  display: flex;
  text-align: center;
  flex-direction: column;
  line-height: 1px;
}

.button-modal-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  justify-content: space-evenly;
}

.button-modal {
  border-radius: 50px;
  width: 280px;
  height: 40px;
  font-size: 24px;
  background-color: #5fb5f3;
  border: none;
  cursor: pointer;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile L */
@media (max-width: 425px) {
  .modal-content {
    font-size: 14px;
    width: 350px;
    height: 250px;
  }

  .text-modal1 {
    font-size: 23px;
  }

  .button-modal {
    width: 200px;
    font-size: 14px;
  }
}
