.register-container {
  width: 100%;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text-container {
  width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.text1 {
  position: relative;
  font-size: 28px;
  word-spacing: 4px;
}

.text2 {
  position: relative;
  font-size: 24px;
}

.text3 {
  position: relative;
  font-size: 24px;
}

.rectangle {
  display: flex;
  margin-left: -170px;
  position: absolute;
  z-index: -1;
}

.Button-and-form {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.form_email {
  position: relative;
  font-size: 22px;
  border-radius: 50px;
  border-color: black;
  width: 300px;
  height: 75px;
  text-indent: 50px;
}

.form_registration {
  position: relative;
  font-size: 22px;
  border-radius: 50px;
  border-color: black;
  width: 300px;
  height: 75px;
  text-indent: 50px;
}

.registration {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-top: 0px;
}

.registration button {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(60deg, #5fb5f3, #f7d984) border-box;
  border: solid transparent;
  border-radius: 50px;
  font-size: 22px;
  width: 300px;
  height: 75px;
  cursor: pointer;
}

/* Laptop L */
@media (max-width: 1440px) {
  .register-container {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .text-container {
    margin-left: 0%;
  }

  .text1 {
    font-size: 20px;
  }

  .text2 {
    position: relative;
    font-size: 17px;
  }

  .text3 {
    position: relative;
    font-size: 17px;
  }

  .rectangle {
    display: flex;
    margin-left: -70px;
    position: absolute;
    z-index: -1;
    width: 100px;
  }

  .Button-and-form {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  .registration {
    width: 350px;
    height: 70px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0px;
  }

  .form_registration {
    width: 350px;
    height: 70px;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  .text-container {
    width: 100%;
  }

  .text1 {
    font-size: 20px;
  }

  .text2 {
    position: relative;
    font-size: 17px;
  }

  .text3 {
    position: relative;
    font-size: 17px;
  }

  .rectangle {
    display: flex;
    margin-left: -70px;
    position: absolute;
    z-index: -1;
    width: 100px;
  }

  .Button-and-form {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  .form_email {
    width: 200px;
    height: 70px;
  }

  .registration {
    width: 200px;
    height: 70px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0px;
  }

  .form_registration {
    width: 200px;
    height: 70px;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .text-container {
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }

  .text1 {
    font-size: 16px;
    margin-left: 100px;
  }

  .text2 {
    position: relative;
    font-size: 13px;
    margin-left: 20px;
  }

  .text3 {
    position: relative;
    font-size: 13px;
    margin-left: 30px;
  }

  .rectangle {
    display: flex;
    position: absolute;
    z-index: -1;
    width: 100px;
  }

  .Button-and-form {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .registration {
    width: 200px;
    height: 70px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0px;
  }

  .form_registration {
    width: 200px;
    height: 70px;
  }

  .form_email {
    width: 200px;
  }
}

/* Mobile L */
@media (max-width: 500px) {
  .text-container {
    width: 420px;
    margin-top: 300px;
  }

  .text1 {
    font-size: 11px;
    margin-left: 120px;
  }

  .text2 {
    position: relative;
    font-size: 9px;
  }

  .text3 {
    position: relative;
    font-size: 9px;
  }

  .rectangle {
    display: flex;
    margin-left: -70px;
    position: absolute;
    z-index: -1;
    width: 100px;
  }

  .Button-and-form {
    position: relative;
    display: flex;
    width: fit-content;
    flex-direction: row;
    gap: 10px;
  }

  .registration button {
    width: 83px;
    height: 25px;
    font-size: 9px;
  }

  .form_registration {
    width: 70px;
    height: 20px;
    font-size: 9px;
    text-indent: 10px;
  }

  .arrow2 {
    display: none;
  }
}

/* Mobile L */
@media (max-width: 425px) {
  .text-container {
    margin-top: 230px;
  }

  .form_email {
    width: 90px;
    height: 20px;
    font-size: 9px;
    text-indent: 10px;
  }

  .form_registration {
    width: 90px;
    height: 20px;
    font-size: 9px;
    text-indent: 10px;
  }

  .registration button {
    width: 90px;
    height: 30px;
    font-size: 9px;
  }

  .text1 {
    left: -60px;
  }

  .text2 {
    font-size: 8.5px;
  }

  .text3 {
    font-size: 8.5px;
  }

  .rectangle {
    width: 50px;
    margin-left: -30px;
  }
}

/* Mobile M */
@media (max-width: 375px) {
  .text-container {
    margin-left: 0;
    width: 375px;
  }
}

/* Mobile S */
@media (max-width: 320px) {
  .text-container {
    margin-left: 280%;
  }
}
