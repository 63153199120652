.footer {
  margin-top: 75px;
  width: 100%;
  height: 100px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ednipro_logo_text {
  display: flex;
  margin-left: 70px;
  gap: 50px;
  text-align: center;
}

.logo_ednipro {
  width: 61px;
  height: 61px;
}

.prava {
  font-size: 24px;
  width: 250px;
}

.budte_z_nami {
  font-size: 24px;
  position: relative;
  display: flex;
}

.social_link {
  width: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Laptop L */
@media (max-width: 1440px) {
  .footer {
    margin-top: 75px;
    width: 100%;
    height: 100px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .budte_z_nami {
    font-size: 24px;
    margin-left: 50px;
    position: relative;
    display: flex;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  .footer {
    margin-top: 75px;
    width: 100%;
    height: 100px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .footer {
    margin-top: 75px;
    width: 100%;
    height: 100px;
    background-color: #ededed;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ednipro_logo_text {
    display: flex;
    margin-left: 20px;
    gap: 20px;
    text-align: center;
    align-items: center;
  }

  .logo_ednipro {
    width: 40px;
    height: 40px;
  }

  .prava {
    font-size: 15px;
    width: 220px;
  }

  .budte_z_nami {
    font-size: 15px;
    position: relative;
    display: flex;
  }

  .social_link {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .instagram {
    width: 30px;
  }

  .telegram {
    width: 30px;
  }

  .facebook {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 425px) {
  .footer {
    margin-top: 75px;
    width: 100%;
    height: 120px;
    background-color: #ededed;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
