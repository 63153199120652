.picture-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  padding-top: 170px;
  justify-content: center;
}

.Toastify {
  position: absolute;
}

.card {
  position: relative;
  width: 407px;
  top: 0;
  left: 0;
  height: 259.09px;
  perspective: 1000px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.5s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card .front .cardimg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.card .back {
  display: flex;
  flex-direction: column;
  transform: rotateY(180deg);
  background: #ededed;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
}

.heart {
  width: 26px;
  height: 24px;
  display: flex;
  gap: 10px;
  font-size: 20px;
  align-items: center;
  color: #fe5e5e;
}

.button-golos {
  border-radius: 50px;
  width: 274px;
  height: 53px;
  font-size: 24px;
  background-color: #5fb5f3;
  border: none;
  cursor: pointer;
}

.buttondiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.buttondiv2 {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.text-back {
  font-size: 20px;
}

@keyframes heart-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.heart-animation {
  animation: heart-animation 1s ease-in-out;
}

/* Laptop L */
@media (max-width: 1440px) {
  .picture-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    padding-top: 100px;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  .picture-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 70px;
    padding-top: 75px;
    justify-content: flex-start;
    align-content: center;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .picture-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 100px;
  }

  .card {
    width: 330px;
  }
}

/* Mobile L */
@media (max-width: 425px) {
}

/* Mobile M */
@media (max-width: 375px) {
  .picture-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 170px;
    margin-left: 50px;
  }

  .card {
    position: relative;
    width: 407px;
    top: 0;
    left: 0;
    height: 259.09px;
  }
}

/* Mobile S */
@media (max-width: 320px) {
}
