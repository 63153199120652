.logo {
  width: 200px;
  height: 120px;
  margin-left: 100px;
}

/* For 4K */
@media (min-width: 2560px) {
  .logo {
    margin-left: 400px;
  }
}

@media (max-width: 500px) {
  .header-container {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
  }

  .logo {
    margin-left: 0;
    height: 75px;
  }
}
