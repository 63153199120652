.top_container {
  position: relative;
  display: flex;
  justify-content: center;
}

.left-right-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 800px;
  margin-left: -60px;
  justify-content: center;
}

.obodok {
  align-items: center;
  background-clip: content-box, border-box;
  background-image: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)),
    linear-gradient(101deg, #5fb5f3, #f7d984);
  background-origin: border-box;
  border: 2px solid transparent;
  border-image-source: none;
  border-radius: 50px;
  box-shadow: inset 2px 1000px 1px #ffffff;
  display: flex;
  font-size: 1.5rem;
  height: 3.4rem;
  justify-content: center;
  width: 700px;
  height: 104px;
}

.text_on_button {
  text-align: center;
  font-size: 40px;
}

.top_text {
  font-size: 23px;
  line-height: 1.5;
  position: relative;
}

.block_text1 {
  line-height: 1;
}

p.tight {
  line-height: 0.6;
}

p.tight2 {
  line-height: 1;
}

.smalltext {
  font-size: 18px;
}

.right {
  width: 800px;
  height: 600px;
  position: relative;
  display: flex;
  margin-left: 100px;
  justify-content: center;
  align-items: center;
}

.ellipse {
  width: 300px;
  position: relative;
  z-index: 1;
  right: 45%;
}

.blue-circle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  margin-left: -10%;
  margin-top: -300px;
  background-color: #5fb5f3;
  position: absolute;
}

.cards_fuck {
  position: absolute;
  height: 350px;
  margin-left: -20%;
  margin-top: -200px;
  z-index: 1;
}

/* Laptop L */
@media (max-width: 1440px) {
  .left-right-wrapper {
    flex-direction: row;
    margin-left: 100px;
  }

  .left,
  .right {
    width: 100%;
    margin-left: 120px;
  }

  .right {
    margin-left: -38%;
    width: 45%;
  }

  .top_text {
    font-size: 16px;
  }

  .text_on_button {
    font-size: 30px;
  }

  .obodok {
    width: 500px;
    height: 60px;
  }

  .smalltext {
    font-size: 16px;
  }

  .blue-circle {
    width: 220px;
    height: 220px;
    margin-top: -170px;
    margin-left: -130px;
  }

  .ellipse {
    width: 200px;
    margin-left: -60px;
  }

  .cards_fuck {
    margin-top: -100px;
    height: 200px;
    margin-left: -220px;
  }
}

/* Laptop */
@media (max-width: 1024px) {
  .left-right-wrapper {
    flex-direction: column;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 1600px;
    width: 100%;
    padding: 0 20px;
  }

  .right {
    width: 100%;
    display: flex;
    margin-left: 30px;
    justify-content: center;
  }

  .left {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  .top_text {
    font-size: 20px;
  }

  .text_on_button {
    font-size: 24px;
    text-align: center;
  }

  .obodok {
    width: 100%;
    height: 60px;
  }

  .smalltext {
    font-size: 16px;
  }

  .blue-circle {
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
  }

  .ellipse {
    right: 26%;
    width: 250px;
    left: -21%;
  }

  .cards_fuck {
    height: 250px;
    left: 44%;
    top: 43%;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .left-right-wrapper {
    flex-direction: column;
    flex-direction: column-reverse;
    margin-left: 50px;
  }

  .left {
    width: 100%;
    align-items: center;
  }

  .right {
    width: 100%;
  }

  .obodok {
    width: 500px;
    display: flex;
    align-items: center;
  }

  .top_text {
    font-size: 18px;
    width: 95%;
  }

  .text_on_button {
    font-size: 24px;
  }

  .smalltext {
    font-size: 14px;
  }

  p.tight {
    line-height: 0.8;
  }

  p.tight2 {
    line-height: 1;
  }
  .blue-circle {
    margin-top: -250px;
    margin-left: -100px;
  }

  .ellipse {
    margin-top: -150px;
    margin-left: -110px;
  }

  .cards_fuck {
    height: 250px;
    margin-top: -200px;
  }
}

/* Mobile L */
@media (max-width: 500px) {
  .left-right-wrapper {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .left,
  .right {
    width: 100%;
  }

  .left {
    margin-left: 0;
  }

  .top_text {
    font-size: 11px;
  }

  .obodok {
    width: 300px;
  }

  .text_on_button {
    font-size: 15px;
  }

  .smalltext {
    font-size: 9px;
  }

  .blue-circle {
    margin-top: -200px;
    margin-left: -50px;
    width: 150px;
    height: 150px;
  }

  .ellipse {
    width: 150px;
    margin-top: -150px;
    left: 100px;
  }

  .cards_fuck {
    height: 160px;
    margin-left: -20%;
    margin-top: -170px;
  }

  .right {
    margin: 0;
    height: 375px;
    padding-top: 50px;
  }
}

@media (max-width: 425px) {
  .left-right-wrapper {
    flex-direction: column-reverse;
  }

  .left {
    width: 100%;
    margin-top: -100px;
  }

  .right {
    width: 100%;
    margin-left: -200px;
  }

  .top_text {
    margin-left: 18px;
    font-size: 16px;
    width: 90%;
  }

  .obodok {
    width: 80%;
  }

  .text_on_button {
    font-size: 20px;
  }

  .smalltext {
    font-size: 12px;
  }

  .blue-circle {
    margin-top: -190px;
    margin-left: 70px;
  }

  .ellipse {
    margin-top: -30%;
    margin-left: -110px;
    width: 140px;
  }

  .cards_fuck {
    height: 140px;
    margin-left: 40px;
    margin-top: -120px;
  }
}

/* Mobile M */
@media (max-width: 375px) {
  .left-right-wrapper {
    flex-direction: column;
  }

  .left,
  .right {
    width: 100%;
  }

  .top_text {
    margin-left: 18px;
    font-size: 16px;
    width: 370px;
  }

  .obodok {
    width: 370px;
  }

  .text_on_button {
    font-size: 20px;
  }

  .smalltext {
    font-size: 12px;
  }

  .right {
    margin-left: -30%;
  }

  .blue-circle {
    margin-top: -300px;
    margin-left: 70px;
  }

  .ellipse {
    margin-top: -150px;
  }

  .cards_fuck {
    height: 200px;
    margin-left: -20%;
    margin-top: -200px;
  }
}
