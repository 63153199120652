@font-face {
  font-family: DNIPROCITY-BLACK;
  src: url("../fonts/DNIPROCITY-BLACK.OTF") format("opentype");
}

@font-face {
  font-family: DNIPROCITY-BOLD;
  src: url("../fonts/DNIPROCITY-BOLD.OTF") format("opentype");
}

@font-face {
  font-family: DNIPROCITY-MEDIUM;
  src: url("../fonts/DNIPROCITY-MEDIUM.OTF") format("opentype");
}

@font-face {
  font-family: DNIPROCITY-REGULAR;
  src: url("../fonts/DNIPROCITY-REGULAR.OTF") format("opentype");
}

* {
  font-family: DNIPROCITY-MEDIUM;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 50px;
}

@media (max-width: 768px) {
  .container_main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0;
  }
}

@media (max-width: 425px) {
  .container_main {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 0 0;
  }
}

@media (max-width: 1440px) {
  .container_main {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
